<template>
    <div class="row">
        <div class="container container--out-pad container--add-pad">
            <div class="education__request-title">оформление заявки</div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 order-clg-2">
                    <div class="education__request-item">
                        Программа
                        <div class="education__request-item-required">*</div>
                    </div>
                    <div class="education__request-item-title">
                        {{ page.title }}
                    </div>
                    <ValidationObserver slim v-slot="{ invalid, handleSubmit }">
                        <form @submit.prevent="handleSubmit(postRequest)">
                            <div v-if="$route.params.id === 'technical-28'">
                                <div class="education__request-form">
                                    <div class="education__request-item">
                                        Формат обучения
                                        <div
                                            class="education__request-item-required"
                                        >
                                            *
                                        </div>
                                    </div>
                                    <ValidationProvider
                                        :name="format_id"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <select
                                            v-model="format_id"
                                            class="form-control"
                                            v-if="page.coursePrice.length > 1"
                                            @change="
                                                () => {
                                                    city = '';
                                                    start_at = '';
                                                }
                                            "
                                        >
                                            <option
                                                v-for="(
                                                    item, id
                                                ) in page.coursePrice"
                                                :key="id"
                                                :value="item.format_name"
                                            >
                                                {{ item.format_title }}
                                            </option>
                                        </select>
                                        <input
                                            v-else
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                page.coursePrice[0].format_title
                                            "
                                        />
                                        <span
                                            class="error-message"
                                            v-if="errors[0]"
                                        >
                                            <br />
                                            **{{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="education__request-form">
                                <div class="education__request-item">
                                    Дата начала обучения
                                    <div
                                        class="education__request-item-required"
                                    >
                                        *
                                    </div>
                                </div>
                                <ValidationProvider
                                    :name="start_at"
                                    rules="required"
                                >
                                    <input
                                        placeholder="Выберите дату"
                                        v-model="start_at"
                                        class="form-control date"
                                        type="text"
                                        id="date"
                                        autocomplete="off"
                                        @blur="getDate"
                                        readonly
                                        style="background-color: #fff"
                                        :disabled="course_id === '9CDB261F-A3FA-6BE8-30C5-160E2EAEFA9B' && format_id === ''"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="education__request-item-desc">
                                Информация о кураторе группы со стороны
                                заказчика
                            </div>
                            <div class="education__request-form">
                                <div class="education__request-item">
                                    ФИО куратора
                                    <div
                                        class="education__request-item-required"
                                    >
                                        *
                                    </div>
                                </div>
                                <ValidationProvider
                                    :name="curator_name"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <input
                                        v-model="curator_name"
                                        class="form-control"
                                        type="text"
                                        maxlength="35"
                                    />
                                    <span
                                        class="error-message"
                                        v-if="errors[0]"
                                    >
                                        <br />
                                        **{{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                            <div class="education__request-form">
                                <div class="education__request-item">
                                    Номер телефона куратора
                                    <div
                                        class="education__request-item-required"
                                    >
                                        *
                                    </div>
                                </div>
                                <ValidationProvider
                                    :name="curator_phone"
                                    rules="phone: 18"
                                    v-slot="{ errors }"
                                >
                                    <input
                                        v-model="curator_phone"
                                        v-mask="'+7-(###)-###-##-##'"
                                        class="form-control"
                                        type="text"
                                    />
                                    <span
                                        class="error-message"
                                        v-if="errors[0]"
                                    >
                                        <br />
                                        **{{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                            <div class="education__request-form">
                                <div class="education__request-item">
                                    E-mail куратора
                                    <div
                                        class="education__request-item-required"
                                    >
                                        *
                                    </div>
                                </div>
                                <ValidationProvider
                                    :name="curator_email"
                                    rules="email"
                                    v-slot="{ errors }"
                                >
                                    <input
                                        v-model="curator_email"
                                        class="form-control"
                                        type="text"
                                    />
                                    <span
                                        class="error-message"
                                        v-if="errors[0]"
                                    >
                                        <br />
                                        **{{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                            <div v-if="$route.params.id !== 'technical-28'" class="education__request-form">
                                <div class="education__request-item">
                                    Формат обучения
                                    <div
                                        class="education__request-item-required"
                                    >
                                        *
                                    </div>
                                </div>
                                <ValidationProvider
                                    :name="format_id"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <select
                                        v-model="format_id"
                                        class="form-control"
                                        v-if="page.coursePrice.length > 1"
                                        @change="
                                            () => {
                                                city = '';
                                                start_at = '';
                                            }
                                        "
                                    >
                                        <option
                                            v-for="(
                                                item, id
                                            ) in page.coursePrice"
                                            :key="id"
                                            :value="item.format_name"
                                        >
                                            {{ item.format_title }}
                                        </option>
                                    </select>
                                    <input
                                        v-else
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            page.coursePrice[0].format_title
                                        "
                                    />
                                    <span
                                        class="error-message"
                                        v-if="errors[0]"
                                    >
                                        <br />
                                        **{{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                            <div class="education__request-form">
                                <div class="education__request-item">
                                    Количество участников
                                    <div
                                        class="education__request-item-required"
                                    >
                                        *
                                    </div>
                                </div>
                                <div class="education__request-people">
                                    <div
                                        class="education__request-people-count"
                                        @click="
                                            user_count === 1
                                                ? user_count
                                                : user_count--
                                        "
                                    >
                                        -
                                    </div>
                                    <div>
                                        <input
                                            class="education__request-people-count"
                                            v-model="user_count"
                                            readonly
                                        />
                                    </div>
                                    <div
                                        class="education__request-people-count"
                                        @click="
                                            user_count === maxUserCount ||
                                            user_count ===
                                                Number(page.max_user_count)
                                                ? user_count
                                                : user_count++
                                        "
                                    >
                                        +
                                    </div>
                                </div>
                                <transition
                                    name="fade"
                                    v-if="
                                        !page.max_user_count &&
                                        !includesFormatType()
                                    "
                                >
                                    <div
                                        class="education__request-people-count-desc"
                                        v-if="user_count < 10"
                                    >
                                        В случае, если количество участников
                                        менее 10, будет организовываться сборный
                                        тренинг с участниками из других компаний
                                    </div>
                                </transition>
                            </div>
                            <transition name="fade">
                                <div
                                    class="education__request-form"
                                    v-if="format_id.indexOf('offline') !== -1"
                                >
                                    <div class="education__request-item">
                                        {{ formatCityField(format_id) }}
                                        <div
                                            class="education__request-item-required"
                                        >
                                            *
                                        </div>
                                    </div>
                                    <ValidationProvider
                                        :name="city"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <input
                                            v-model="city"
                                            class="form-control"
                                            type="text"
                                            maxlength="35"
                                        />
                                        <span
                                            class="error-message"
                                            v-if="errors[0]"
                                        >
                                            <br />
                                            **{{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                    <div
                                        class="education__request-people-count-desc"
                                    >
                                        Командировочные расходы на перелет и
                                        проживание тренера оплачиваются
                                        дополнительно.
                                    </div>
                                </div>
                            </transition>
                            <div class="education__request-sum">
                                <transition name="fade">
                                    <div
                                        class="education__request-price"
                                        v-if="sum != 0"
                                    >
                                        Итого:
                                        {{ reSum }}
                                        баллов
                                    </div>
                                </transition>
                                <div class="education__request-price">
                                    Баланс:
                                    {{ reBalance }}
                                    баллов
                                </div>
                                <div
                                    class="education__request-price-error"
                                    v-if="error_balance < 0"
                                >
                                    Не хватает:
                                    {{ reError_balance }} баллов
                                </div>
                                <div class="education__button">
                                    <button
                                        :class="
                                            invalid ||
                                            error_balance <= 0 ||
                                            curator_email === '' ||
                                            disabled
                                                ? 'education__button-btn disabled'
                                                : 'education__button-btn'
                                        "
                                        type="submit"
                                    >
                                        ОФОРМИТЬ ЗАЯВКУ
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 order-clg-1">
                    <div class="education__detail-description-list-title">
                        Для кого тренинг?
                    </div>
                    <div class="education__detail-description-list">
                        <div class="education__detail-description-text">
                            {{ page.audience }}
                        </div>
                    </div>
                    <div class="education__detail-description-list-title">
                        Продолжительность
                    </div>
                    <div class="education__detail-description-list">
                        <div class="education__detail-description-text">
                            {{ timeCourse }}
                        </div>
                    </div>
                    <div class="education__detail-description-list-title">
                        Формат обучения
                    </div>
                    <div class="education__detail-description">
                        <ul
                            class="education__detail-description-ul"
                            v-for="(item, id) in this.page.coursePrice"
                            :key="id"
                        >
                            <li class="education__detail-description-ul-item">
                                {{ item.format_title }}
                            </li>
                        </ul>
                    </div>
                    <div
                        data-v-343cc5f0=""
                        class="education__detail-description-list-title"
                    >
                        Стоимость обучения*
                    </div>
                    <div
                        data-v-343cc5f0=""
                        class="education__detail-description"
                    >
                        <div data-v-343cc5f0="" class="education__detail-table">
                            <div
                                data-v-343cc5f0=""
                                class="education__detail-table-item"
                            >
                                Формат обучения
                            </div>
                            <div
                                data-v-343cc5f0=""
                                class="education__detail-table-item"
                            >
                                {{
                                    this.page.is_full_price === '1'
                                        ? 'Стоимость за услугу* (руб.)'
                                        : 'Стоимость за участника* (руб.)'
                                }}
                            </div>
                        </div>
                        <div
                            class="education__detail-table"
                            v-for="(item, id) in page.coursePrice"
                            :key="id"
                        >
                            <div class="education__detail-table-item">
                                {{ item.format_title }}
                            </div>
                            <div class="education__detail-table-item">
                                {{
                                    String(item.price).replace(
                                        /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                                        '$1 '
                                    )
                                }}
                            </div>
                        </div>
                        <div v-if="!includesFormatType()">
                            <div
                                class="education__detail-description-list-title"
                            >
                                Размер группы (кол-во человек в одной группе)
                            </div>
                            <div
                                class="education__detail-description"
                                v-if="page.max_user_count"
                            >
                                <div class="education__detail-description-text">
                                    Максимальное количество участников для
                                    проведения обучения –
                                    {{ page.max_user_count }} человек.
                                </div>
                            </div>
                            <div class="education__detail-description" v-else>
                                <div class="education__detail-description-text">
                                    Минимальное количество участников для
                                    проведения обучения – 10 человек.
                                </div>
                                <div class="education__detail-description-text">
                                    В случае, если количество участников менее
                                    10, формируется сборная группа из нескольких
                                    компаний-дилеров
                                </div>
                            </div>
                        </div>
                        <div
                            class="education__detail-footer-description"
                            v-if="page.additional_description"
                        >
                            <div
                                class="education__detail-footer-description-title"
                            >
                                Дополнительно
                            </div>
                            <div
                                class="education__detail-footer-description-text"
                            >
                                {{ page.additional_description }}
                            </div>
                        </div>
                        <div
                            class="education__detail-footer-description"
                            v-else
                        >
                            <div
                                class="education__detail-footer-description-title"
                            >
                                Дополнительно
                            </div>
                            <div
                                class="education__detail-footer-description-text"
                            >
                                *Стоимость указана без учёта НДС. Компания
                                Digital Concept НДС не облагается, в&nbsp;связи
                                с&nbsp;применением упрощённой системы
                                налогообложения.
                                <p>
                                    **Дополнительные расходы, связанные
                                    с&nbsp;перелетом и проживанием участников,
                                    оплачиваются дополнительно (при проведении
                                    очного сборного тренинга). Дополнительные
                                    расходы по&nbsp;организации очного обучения
                                    (аренда аудитории и&nbsp;оборудования,
                                    питание для участников) оплачиваются
                                    дополнительно. Дополнительные расходы,
                                    связанные с&nbsp;командировочными затратами
                                    на&nbsp;перелет и&nbsp;проживание тренера
                                    оплачиваются дополнительно (при проведении
                                    очного тренинга в&nbsp;городе Заказчика,
                                    за&nbsp;исключением Санкт-Петербурга).
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Datepicker } from 'vanillajs-datepicker';
    import ru from 'vanillajs-datepicker/js/i18n/locales/ru.js';
    import router from '@/router';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { get, post } from '@/lib/axios';
    import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                page: '',
                datePicker: null,
                user_count: 10,
                maxUserCount: 20,
                start_at: '',
                curator_name: '',
                curator_phone: '',
                curator_email: '',
                city: '',
                course_id: '',
                format_id: '',
                disabled: false,
                dates: [],
                substitutionArray: [
                    'Комплексное решение по повышению безопасности бизнеса в области потребительского экстремизма',
                    'Аудит бизнес-процессов автосервиса',
                    'Открытие поста аппаратной замены масла в АКПП: оборудование, технологии, обучение, техподдержка',
                ],
            };
        },
        methods: {
            includesFormatType() {
                if (this.substitutionArray.includes(this.page.title)) {
                    return true;
                }
                return false;
            },
            getDatesDisabled(selectedDates) {
                const startDate = new Date('2022-01-01');
                const endDate = new Date('2025-12-31');
                const dates = [];
                for (
                    let d = startDate;
                    d <= endDate;
                    d.setDate(d.getDate() + 1)
                ) {
                    dates.push(new Date(d));
                }
                const selected = selectedDates.map(date => new Date(date));
                const unselected = dates.filter(
                    date =>
                        !selected.some(
                            selectedDate =>
                                selectedDate.getTime() === date.getTime()
                        )
                );
                const formatted = unselected.map(date => {
                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1)
                        .toString()
                        .padStart(2, '0');
                    const year = date.getFullYear().toString();
                    return `${year}/${month}/${day}`;
                });
                return formatted;
            },
            async getPeopleCount(event) {
                const date = new Date(
                    Number(event.dataset.date)
                ).toLocaleDateString('RU-ru');
                const userCount = await get(
                    `education/people/${this.page.id}?date=${date}`
                ).then(response => {
                    return +response.data.count;
                });
                if (userCount >= this.page.max_user_count) {
                    event.setAttribute(
                        'data-tooltip-education',
                        `Доступных мест нет`
                    );
                    this.maxUserCount = 1;
                    this.user_count = 1;
                    this.datePicker.dates = [];
                    const element = document.querySelector('#date');
                    element.value = '';
                    return;
                }
                event.setAttribute(
                    'data-tooltip-education',
                    `Доступно ${
                        this.page.max_user_count
                            ? this.page.max_user_count - userCount
                            : 20 - userCount
                    } мест`
                );
                this.maxUserCount = this.page.max_user_count
                    ? this.page.max_user_count - userCount
                    : 20 - userCount;
                this.user_count = 1;
                this.start_at = this.getFormattedDate(this.datePicker.dates[0]);
            },
            async fetchData() {
                await get(`/education/course/${this.$route.params.id}`)
                    .then(response => {
                        this.page = response.data;
                    })
                    .catch(e => {
                        console.log(e);
                    });
                const getDates = await get(
                    `education/dates/${this.page.id}`
                ).then(response => {
                    return response.data;
                });
                if (this.substitutionArray.includes(this.page.title)) {
                    this.user_count = 1;
                }
                this.dates = getDates;
                if (this.page.coursePrice.length === 1) {
                    this.format_id = this.page.coursePrice[0].format_name;
                }
                this.course_id = this.page.id;
                Object.assign(Datepicker.locales, ru);
                const date = document.getElementById('date');
                const D = new Date();
                const Dy = new Date();
                Dy.setFullYear(Dy.getFullYear() + 3);
                if (getDates.length > 0) {
                    const dates = this.getDatesDisabled(getDates);
                    this.datePicker = new Datepicker(date, {
                        format: 'yyyy/mm/dd',
                        language: 'ru',
                        defaultViewDate: D,
                        datesDisabled: dates,
                        minDate: D,
                        maxDate: Dy,
                    });
                    const state = this;
                    const cell = document.querySelectorAll(
                        '.datepicker-cell.day'
                    );
                    cell.forEach(item => {
                        if (!item.classList.contains('disabled')) {
                            item.addEventListener('click', function (event) {
                                state.getPeopleCount(event.target);
                            });
                        }
                    });
                    const next = document.querySelector('.next-btn');
                    next.addEventListener('click', function () {
                        cell.forEach(item => {
                            item.removeAttribute('data-tooltip-education');
                        });
                    });
                    const prev = document.querySelector('.prev-btn');
                    prev.addEventListener('click', function () {
                        cell.forEach(item => {
                            item.removeAttribute('data-tooltip-education');
                        });
                    });
                    return;
                }

                if (this.substitutionArray.includes(this.page.title)) {
                    this.datePicker = new Datepicker(date, {
                        format: 'yyyy/mm/dd',
                        language: 'ru',
                        defaultViewDate: D,
                        minDate: D,
                        maxDate: Dy,
                    });
                    date.addEventListener('changeDate', function (e) {
                        const a =
                            document.getElementsByClassName('datepicker-cell');
                        for (let i = 0; i < a.length; i++) {
                            if (
                                a[i].className ===
                                'datepicker-cell day selected focused'
                            ) {
                                a[i + 1].classList.add('focused');
                            }
                        }
                    });
                    return;
                }

                if (this.page.courseType.name === 'technical') {
                    D.setMonth(D.getMonth() + 3);
                } else {
                    D.setDate(D.getDate() + 14);
                }

                this.page.courseType.name === 'technical'
                    ? (this.datePicker = new Datepicker(date, {
                          format: 'yyyy/mm/dd',
                          daysOfWeekDisabled: [0, 4, 5, 6],
                          language: 'ru',
                          defaultViewDate: D,
                          minDate: D,
                          maxDate: Dy,
                      }))
                    : (this.datePicker = new Datepicker(date, {
                          format: 'yyyy/mm/dd',
                          language: 'ru',
                          defaultViewDate: D,
                          minDate: D,
                          maxDate: Dy,
                      }));
                this.page.hours === '24'
                    ? date.addEventListener('changeDate', function (e) {
                          const a =
                              document.getElementsByClassName(
                                  'datepicker-cell'
                              );
                          for (let i = 0; i < a.length; i++) {
                              if (
                                  a[i].className ===
                                  'datepicker-cell day selected focused'
                              ) {
                                  a[i + 1].classList.add('focused');
                                  a[i + 2].classList.add('focused');
                              }
                          }
                      })
                    : date.addEventListener('changeDate', function (e) {
                          const a =
                              document.getElementsByClassName(
                                  'datepicker-cell'
                              );
                          for (let i = 0; i < a.length; i++) {
                              if (
                                  a[i].className ===
                                  'datepicker-cell day selected focused'
                              ) {
                                  a[i + 1].classList.add('focused');
                              }
                          }
                      });
            },
            getFormattedDate(value) {
                let date = new Date(value);

                let month = date.getMonth() + 1;
                let day = date.getDate();
                const fullYear = date.getFullYear();

                month = (month < 10 ? '0' : '') + month;
                day = (day < 10 ? '0' : '') + day;
                if (this.datePicker.dates[0]) {
                    return `${fullYear}/${month}/${day}`;
                }
            },
            getDate() {
                this.start_at = this.getFormattedDate(this.datePicker.dates[0]);
            },
            formatCityField(type) {
                if (type) {
                    const course = this.page.coursePrice.find(
                        ({ format_name }) => format_name === type
                    );

                    if (
                        course.format_title ===
                            'Офлайн, на учебно-практической площадке ИЦ СМАРТ в г. Москве' ||
                        course.format_title ===
                            'Офлайн, на учебно-практической площадке ИЦ СМАРТ в г. Москве + онлайн'
                    ) {
                        this.city = 'г. Москва';
                    }

                    if (this.substitutionArray.includes(this.page.title)) {
                        return 'Адрес автосервиса (место проведения обучения)';
                    }
                }
                return 'Город проведения';
            },
            postRequest() {
                this.disabled = true;
                const {
                    curator_name,
                    curator_phone,
                    curator_email,
                    city,
                    user_count,
                    start_at,
                    course_id,
                } = this;
                const format_id =
                    this.page.coursePrice.length > 1
                        ? this.page.coursePrice.find(
                              ({ format_name }) =>
                                  format_name === this.format_id
                          ).id
                        : this.page.coursePrice[0].id;
                const data = {
                    curator_name,
                    curator_phone,
                    curator_email,
                    city,
                    user_count,
                    start_at,
                    course_id,
                    format_id,
                };
                if (this.error_balance > 0) {
                    post('education/course/order', data)
                        .then(() => {
                            this.$router.replace({ name: 'SuccessfulOrder' });
                            this.disabled = false;
                        })
                        .catch(e => {
                            console.log(data);
                            this.disabled = false;
                            console.log(e);
                        });
                }
            },
        },
        beforeMount() {
            this.fetchData();
        },
        watch: {
            user_count(newCount) {
                if (newCount > 20) {
                    this.user_count = 20;
                }
            },
            curator_name() {
                if (this.curator_name.charAt(0) === ' ') {
                    this.curator_name = '';
                }
            },
            curator_email() {
                const reg = /[а-яА-ЯёЁ\s]/g;
                if (this.curator_email.search(reg) != -1) {
                    this.curator_email = this.curator_email.replace(reg, '');
                }
            },
            city() {
                if (this.city.charAt(0) === ' ') {
                    this.city = '';
                }
            },
            format_id(newFormat) {
                const date = document.getElementById('date');
                const D = new Date();
                const Dy = new Date();
                Dy.setFullYear(Dy.getFullYear() + 3);

                if (
                    newFormat === 'offline1' &&
                    this.page.title ===
                        'Технологии обслуживания клиентов технических центров'
                ) {
                    this.datePicker.destroy();
                    this.datePicker = new Datepicker(date, {
                        format: 'yyyy/mm/dd',
                        language: 'ru',
                        defaultViewDate: D,
                        minDate: D,
                        maxDate: Dy,
                    });
                    date.addEventListener('changeDate', function (e) {
                        const a =
                            document.getElementsByClassName('datepicker-cell');
                        for (let i = 0; i < a.length; i++) {
                            if (
                                a[i].className ===
                                'datepicker-cell day selected focused'
                            ) {
                                a[i + 1].classList.add('focused');
                            }
                        }
                    });
                } else if (
                    this.page.title ===
                    'Технологии обслуживания клиентов технических центров'
                ) {
                    this.datePicker.destroy();
                    const dates = this.getDatesDisabled(this.dates);
                    this.datePicker = new Datepicker(date, {
                        format: 'yyyy/mm/dd',
                        language: 'ru',
                        defaultViewDate: D,
                        datesDisabled: dates,
                        minDate: D,
                        maxDate: Dy,
                    });
                    const state = this;
                    const cell = document.querySelectorAll('.datepicker-cell');
                    cell.forEach(item => {
                        item.addEventListener('click', function (event) {
                            state.getPeopleCount(event.target);
                        });
                    });
                } else if (
                    this.page.title ===
                    'Основы экономики и бизнес-процессы автосервиса'
                ) {
                    this.datePicker.destroy();
                    const datesEnabled = ['2024/03/27'];
                    const dates = this.getDatesDisabled(this.dates).filter((date) => !datesEnabled.includes(date));
                    this.datePicker = new Datepicker(date, {
                        format: 'yyyy/mm/dd',
                        language: 'ru',
                        defaultViewDate: D,
                        datesDisabled: dates,
                        minDate: D,
                        maxDate: Dy,
                    });
                    const state = this;
                    const cell = document.querySelectorAll('.datepicker-cell');
                    cell.forEach(item => {
                        item.addEventListener('click', function (event) {
                            state.getPeopleCount(event.target);
                        });
                    });
                } else if (
                    this.page.title ===
                    'Диагностика и обслуживание электронных систем автомобилей'
                ) {
                    this.datePicker.destroy();
                    const datesEnabled = [
                        '2024/04/01',
                        '2024/04/29',
                        '2024/05/27',
                        '2024/06/24',
                        '2024/07/22',
                        '2024/08/19',
                        '2024/09/16',
                        '2024/10/14',
                        '2024/11/18',
                        '2024/12/16',
                    ];
                    const dates = this.getDatesDisabled(this.dates).filter((date) => !datesEnabled.includes(date));
                    this.datePicker = new Datepicker(date, {
                        format: 'yyyy/mm/dd',
                        language: 'ru',
                        defaultViewDate: D,
                        datesDisabled: dates,
                        minDate: D,
                        maxDate: Dy,
                    });
                    const state = this;
                    const cell = document.querySelectorAll('.datepicker-cell');
                    cell.forEach(item => {
                        item.addEventListener('click', function (event) {
                            state.getPeopleCount(event.target);
                        });
                    });
                }
            },
        },
        computed: {
            ...mapGetters('account', ['accountInfo', 'pointsNumber']),
            sum() {
                const selectItem = this.page.coursePrice.find(
                    ({ format_name }) => format_name === this.format_id
                );
                if (selectItem?.price && this.page.is_full_price) {
                    return selectItem.price;
                }
                if (selectItem?.price) {
                    return this.user_count * selectItem.price;
                }
                return 0;
            },
            timeCourse() {
                if (this.page.additional_description) {
                    return this.page.course_duration;
                }
                return this.page.hours === '16'
                    ? `${this.page.hours} часов (2 дня)`
                    : `${this.page.hours} часа (3 дня)`;
            },
            reError_balance() {
                const error = (this.pointsNumber - this.sum) * -1;
                return String(error).replace(
                    /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                    '$1 '
                );
            },
            reSum() {
                const newSumma = String(this.sum).replace(
                    /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                    '$1 '
                );
                return newSumma;
            },
            reBalance() {
                const newBalance = String(this.pointsNumber).replace(
                    /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                    '$1 '
                );
                return newBalance;
            },
            error_balance() {
                return this.pointsNumber - this.sum;
            },
        },
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        metaInfo() {
            return {
                title: `Оформление заявки - ЛУКМАРКЕТ`,
            };
        },
    };
</script>

<style lang="sass">

    .error-message
      color: #D2233C

    .fade-enter-active, .fade-leave-active
      transition: opacity .3s

    .fade-enter, .fade-leave-to
      opacity: 0


    .education__request
      &-title
        font-size: 24px
        line-height: 25px
        text-transform: uppercase
        font-weight: 700
        padding: 60px 0 20px 0

      &-price
        font-weight: 700
        font-size: 24px
        line-height: 25px
        margin: 20px 0

        &-error
          font-size: 16px
          font-weight: 400
          color: #D2233C
          margin: 0 0 27px 0

      &-sum
        margin: 40px 0

      &-people
        display: flex
        width: calc(36px * 3)
        justify-content: space-between

        &-count
          border: 1px solid #E9E9E9
          padding: 8px
          font-size: 18px
          line-height: 19px
          width: 100%
          text-align: center
          font-weight: 400
          cursor: pointer
          transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out

          &:hover
            border-color: #80bdff

          &:nth-child(2)
            cursor: default

            &:hover
              border: 1px solid #E9E9E9

          &-desc
            color: #D2233C
            font-size: 16px
            font-weight: 400
            line-height: 17px
            margin: 20px 0

      &-item
        display: flex
        flex-direction: row
        font-size: 18px
        line-height: 19px
        font-weight: 400
        margin: 20px 0

        &-desc
          margin: 40px 0
          font-size: 18px
          line-height: 19px

        &-required
          color: #D2233C

        &-title
          background-color: #FAFAFA
          color: #000000
          font-size: 18px
          font-weight: 400
          line-height: 19px
          width: 100%
          padding: 13px

    .date
      width: 262px

    .row
      margin: 0

    .education__detail
      &-footer-description
        padding: 20px
        margin: 40px 0
        background: #F7F7F9

        &-title
          color: #D2233C
          font-size: 14px
          font-weight: 400
          line-height: 14.74px

        &-text
          font-weight: 400
          font-size: 12px
          line-height: 12.64px
          padding-top: 12px

      &-description
        color: #4A4A5A
        font-size: 16px
        line-height: 17px
        margin: 20px 0

        &-text
          font-size: 18px
          font-weight: 400
          line-height: 19px
          color: #000000
          margin: 20px 0
          max-width: 511px
          width: 100%

        &-ul
          margin: 20px -20px

          &-item
            font-size: 18px
            font-weight: 400
            line-height: 19px
            color: #000
            margin: 12px 0

        &-list
          display: flex
          flex-direction: row
          align-items: center

          &-title
            margin: 20px 0 0px 0
            font-weight: 700
            color: #D2233C
            font-size: 18px
            line-height: 19px

          &-text
            padding: 12px 30px
            color: #000
            font-weight: 400
            font-size: 18px
            line-height: 19px

    .education__button
      display: block
      text-align: left

      &-btn
        min-width: 275px
        text-align: center
        display: inline-block
        background: #d2233c
        border-radius: 4px
        color: #fff !important
        text-transform: uppercase
        font: 700 16px/24px Futuris
        margin-top: 20px
        padding: 10px
        text-decoration: none
        cursor: pointer

        &.disabled
          background-color: #777
          border-color: #777
          cursor: not-allowed

    .education__detail-table
      color: #000000
      background-color: #EFEFEF
      max-width: 544px
      width: 100%
      display: flex
      padding: 8px 12px 8px 8px
      border: 1px solid #fff

      &-item
        width: 50%
        padding: 0 5px

      &:nth-child(2)
        background: #F4F4F4


    [data-tooltip-education]
      position: relative

    [data-tooltip-education]::before
      content: attr(data-tooltip-education)
      position: absolute
      width: 130px
      height: 33px
      font-size: 12px
      line-height: 12px
      left: 0px
      bottom: 35px
      background: #F7F7F9
      color: #000000
      padding: 10px
      pointer-events: none
      opacity: 0
      transition: 0.3s ease-in-out

    [data-tooltip-education]:hover::after
      content: " "
      position: absolute
      bottom: 110%
      left: 50%
      margin-left: -5px
      width: 12px
      height: 12px
      border-top: 10px solid #F7F7F9
      border-right: 6px solid transparent
      border-left: 6px solid transparent
      font-size: 0
      line-height: 0


    [data-tooltip-education]:hover::before
      opacity: 1
      bottom: 50px
</style>
